import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  PAGINATION_API,
  PRODUCT_CATEGORY_CONTROLLER,
} from "../../utils/actionTypes";
import { apiService } from "../../services/api";

export const getCategoriesWithPagination = createAsyncThunk(
  "category/getCategoriesWithPagination",
  async (
    { page, pageSize, filters, sorting, defaultSorting, globalSearch },
    { rejectWithValue }
  ) => {
    try {
      const filterArray = Object.entries(filters)
        .map(([field, value]) => ({
          field,
          value,
          operator: typeof value === "boolean" ? "equals" : "contains",
        }))
        .filter((f) => f.value !== undefined && f.value !== "");

      const requestBody = {
        page,
        pageSize,
        filters: filterArray,
        sorting: sorting || defaultSorting,
        globalSearch: globalSearch || "",
      };

      const response = await apiService.post(
        `/${PRODUCT_CATEGORY_CONTROLLER}/${PAGINATION_API}`,
        requestBody
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCategories = createAsyncThunk(
  "category/getCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiService.get(`/${PRODUCT_CATEGORY_CONTROLLER}`);

      if (!response) {
        throw new Error("Data Fetching failed");
      }

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getCategory = createAsyncThunk(
  "category/getCategory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiService.get(
        `/${PRODUCT_CATEGORY_CONTROLLER}/${id}`
      );

      if (!response) {
        throw new Error("Data Fetching failed");
      }

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const saveCategory = createAsyncThunk(
  "category/saveCategory",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await apiService.post(
        `/${PRODUCT_CATEGORY_CONTROLLER}`,
        obj
      );

      if (!response) {
        throw new Error("Data Fetching failed");
      }

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateCategory = createAsyncThunk(
  "category/updateCategory",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await apiService.put(
        `/${PRODUCT_CATEGORY_CONTROLLER}/${obj.id}`,
        obj
      );

      if (!response) {
        throw new Error("Data Fetching failed");
      }

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "category/deleteCategory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiService.delete(
        `/${PRODUCT_CATEGORY_CONTROLLER}/${id}`
      );

      if (!response) {
        throw new Error("Data Fetching failed");
      }

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState: {
    categories: [],
    categoriesComboList: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategoriesWithPagination.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCategoriesWithPagination.fulfilled, (state, action) => {
        state.categories = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getCategoriesWithPagination.rejected, (state, action) => {
        state.categories = [];
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.categoriesComboList =
          data &&
          data.length > 0 &&
          data.map((x) => ({
            label: x.name,
            value: x.id,
          }));
        state.loading = false;
        state.error = null;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.categoriesComboList = [];
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(saveCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(updateCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(deleteCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default categorySlice.reducer;
