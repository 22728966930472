import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  PAGINATION_API,
  BLOG_CONTROLLER,
  BLOG_CATEGORY_CONTROLLER,
} from "../../utils/actionTypes";
import { apiService } from "../../services/api";

export const getBlogsWithPagination = createAsyncThunk(
  "blog/getBlogsWithPagination",
  async (
    { page, pageSize, filters, sorting, defaultSorting, globalSearch },
    { rejectWithValue }
  ) => {
    try {
      const filterArray = Object.entries(filters)
        .map(([field, value]) => ({
          field,
          value,
          operator: typeof value === "boolean" ? "equals" : "contains",
        }))
        .filter((f) => f.value !== undefined && f.value !== "");

      const requestBody = {
        page,
        pageSize,
        filters: filterArray,
        sorting: sorting || defaultSorting,
        globalSearch: globalSearch || "",
      };

      const response = await apiService.post(
        `/${BLOG_CONTROLLER}/${PAGINATION_API}`,
        requestBody
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getBlogs = createAsyncThunk(
  "blog/getBlogs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiService.get(`/${BLOG_CONTROLLER}`);

      if (!response) {
        throw new Error("Data Fetching failed");
      }

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getBlog = createAsyncThunk(
  "blog/getBlog",
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiService.get(`/${BLOG_CONTROLLER}/${id}`);

      if (!response) {
        throw new Error("Data Fetching failed");
      }

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const saveBlog = createAsyncThunk(
  "blog/saveBlog",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await apiService.post(`/${BLOG_CONTROLLER}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (!response) {
        throw new Error("Data Fetching failed");
      }

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateBlog = createAsyncThunk(
  "blog/updateBlog",
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const response = await apiService.put(
        `/${BLOG_CONTROLLER}/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (!response) {
        throw new Error("Data Fetching failed");
      }

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteBlog = createAsyncThunk(
  "blog/deleteBlog",
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiService.delete(`/${BLOG_CONTROLLER}/${id}`);

      if (!response) {
        throw new Error("Data Fetching failed");
      }

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getBlogCategories = createAsyncThunk(
  "blog/getBlogCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiService.get(`/${BLOG_CONTROLLER}/blog-categories`);

      if (!response) {
        throw new Error("Failed to fetch blog categories");
      }

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getBlogCategoriesWithPagination = createAsyncThunk(
  "blog/getBlogCategoriesWithPagination",
  async (
    { page, pageSize, filters, sorting, defaultSorting, globalSearch },
    { rejectWithValue }
  ) => {
    try {
      const filterArray = Object.entries(filters)
        .map(([field, value]) => ({
          field,
          value,
          operator: typeof value === "boolean" ? "equals" : "contains",
        }))
        .filter((f) => f.value !== undefined && f.value !== "");

      const requestBody = {
        page,
        pageSize,
        filters: filterArray,
        sorting: sorting || defaultSorting,
        globalSearch: globalSearch || "",
      };

      const response = await apiService.post(
        `/${BLOG_CONTROLLER}/blog-categories/pagination`,
        requestBody
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getBlogCategory = createAsyncThunk(
  "blog/getBlogCategory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiService.get(
        `/${BLOG_CONTROLLER}/blog-categories/${id}`
      );

      if (!response) {
        throw new Error("Failed to fetch blog category");
      }

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const saveBlogCategory = createAsyncThunk(
  "blog/saveBlogCategory",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await apiService.post(
        `/${BLOG_CONTROLLER}/blog-categories`,
        obj
      );

      if (!response) {
        throw new Error("Failed to create blog category");
      }

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateBlogCategory = createAsyncThunk(
  "blog/updateBlogCategory",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await apiService.put(
        `/${BLOG_CONTROLLER}/blog-categories/${obj.id}`,
        obj
      );

      if (!response) {
        throw new Error("Failed to update blog category");
      }

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteBlogCategory = createAsyncThunk(
  "blog/deleteBlogCategory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiService.delete(
        `/${BLOG_CONTROLLER}/blog-categories/${id}`
      );

      if (!response) {
        throw new Error("Failed to delete blog category");
      }

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const blogSlice = createSlice({
  name: "blog",
  initialState: {
    blogs: [],
    blogsComboList: [],
    categories: [],
    categoriesComboList: [],
    blogCategories: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBlogsWithPagination.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBlogsWithPagination.fulfilled, (state, action) => {
        state.blogs = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getBlogsWithPagination.rejected, (state, action) => {
        state.blogs = [];
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getBlogs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBlogs.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.blogsComboList =
          data &&
          data.length > 0 &&
          data.map((x) => ({
            label: x.title,
            value: x.id,
          }));
        state.loading = false;
        state.error = null;
      })
      .addCase(getBlogs.rejected, (state, action) => {
        state.blogsComboList = [];
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(saveBlog.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveBlog.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveBlog.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(updateBlog.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateBlog.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateBlog.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(deleteBlog.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteBlog.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteBlog.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getBlogCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBlogCategories.fulfilled, (state, action) => {
        const data = action.payload.data.data;
        state.categoriesComboList =
          data &&
          data.length > 0 &&
          data.map((x) => ({
            label: x.name,
            value: x.id,
          }));
        state.loading = false;
        state.error = null;
      })
      .addCase(getBlogCategories.rejected, (state, action) => {
        state.categories = [];
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getBlogCategoriesWithPagination.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBlogCategoriesWithPagination.fulfilled, (state, action) => {
        state.blogCategories = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getBlogCategoriesWithPagination.rejected, (state, action) => {
        state.blogCategories = [];
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(saveBlogCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveBlogCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveBlogCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(updateBlogCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateBlogCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateBlogCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(deleteBlogCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteBlogCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteBlogCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getBlogCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBlogCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(getBlogCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default blogSlice.reducer;
