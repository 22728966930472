import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  PAGINATION_API,
  PRODUCT_SUBCATEGORY_CONTROLLER,
} from "../../utils/actionTypes";
import { apiService } from "../../services/api";

export const getSubCategoriesWithPagination = createAsyncThunk(
  "subcategory/getSubCategoriesWithPagination",
  async (
    { page, pageSize, filters, sorting, defaultSorting, globalSearch },
    { rejectWithValue }
  ) => {
    try {
      const filterArray = Object.entries(filters)
        .map(([field, value]) => ({
          field,
          value,
          operator: typeof value === "boolean" ? "equals" : "contains",
        }))
        .filter((f) => f.value !== undefined && f.value !== "");

      const requestBody = {
        page,
        pageSize,
        filters: filterArray,
        sorting: sorting || defaultSorting,
        globalSearch: globalSearch || "",
      };

      const response = await apiService.post(
        `/${PRODUCT_SUBCATEGORY_CONTROLLER}/${PAGINATION_API}`,
        requestBody
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getSubCategories = createAsyncThunk(
    "subcategory/getSubCategories",
    async (_, { rejectWithValue }) => {
      try {
        const response = await apiService.get(`/${PRODUCT_SUBCATEGORY_CONTROLLER}`);
  
        if (!response) {
          throw new Error("Data Fetching failed");
        }
  
        return response.data || null;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );
  export const getSubCategoriesByCategory = createAsyncThunk(
    "subcategory/getSubCategoriesByCategory",
    async (category_id, { rejectWithValue }) => {
      try {
        const response = await apiService.get(`/${PRODUCT_SUBCATEGORY_CONTROLLER}/category/${category_id}`);
  
        if (!response) {
          throw new Error("Data Fetching failed");
        }
  
        return response.data || null;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const getSubCategory = createAsyncThunk(
  "subcategory/getSubCategory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiService.get(
        `/${PRODUCT_SUBCATEGORY_CONTROLLER}/${id}`
      );

      if (!response) {
        throw new Error("Data Fetching failed");
      }

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const saveSubCategory = createAsyncThunk(
  "subcategory/saveSubCategory",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await apiService.post(
        `/${PRODUCT_SUBCATEGORY_CONTROLLER}`,
        obj
      );

      if (!response) {
        throw new Error("Data Fetching failed");
      }

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateSubCategory = createAsyncThunk(
  "subcategory/updateSubCategory",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await apiService.put(
        `/${PRODUCT_SUBCATEGORY_CONTROLLER}/${obj.id}`,
        obj
      );

      if (!response) {
        throw new Error("Data Fetching failed");
      }

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteSubCategory = createAsyncThunk(
  "subcategory/deleteSubCategory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiService.delete(
        `/${PRODUCT_SUBCATEGORY_CONTROLLER}/${id}`
      );

      if (!response) {
        throw new Error("Data Fetching failed");
      }

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const subcategorySlice = createSlice({
  name: "subcategory",
  initialState: {
    subcategories: [],
    subcategoriesComboList: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubCategoriesWithPagination.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSubCategoriesWithPagination.fulfilled, (state, action) => {
        state.subcategories = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getSubCategoriesWithPagination.rejected, (state, action) => {
        state.subcategories = [];
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getSubCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSubCategories.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.subcategoriesComboList =
          data &&
          data.length > 0 &&
          data.map((x) => ({
            label: x.name,
            value: x.id,
          }));
        state.loading = false;
        state.error = null;
      })
      .addCase(getSubCategories.rejected, (state, action) => {
        state.subcategoriesComboList = [];
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSubCategoriesByCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSubCategoriesByCategory.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.subcategoriesComboList =
          data &&
          data.length > 0 &&
          data.map((x) => ({
            label: x.name,
            value: x.id,
          }));
        state.loading = false;
        state.error = null;
      })
      .addCase(getSubCategoriesByCategory.rejected, (state, action) => {
        state.subcategoriesComboList = [];
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(saveSubCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveSubCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveSubCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(updateSubCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSubCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateSubCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(deleteSubCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSubCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteSubCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default subcategorySlice.reducer; 