import {
  Button,
  Drawer,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Upload,
  DatePicker,
  Switch,
  Tooltip,
  message,
} from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InboxOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BASE_DOC_URL } from "../../../utils/actionTypes";
import {
  saveBlog,
  updateBlog,
  getBlogCategories,
} from "../../../store/blog/blogSlice";
import dayjs from "dayjs";

const ALLOWED_IMAGE_TYPES = [
  "image/jpeg",
  "image/png",
  "image/webp",
  "image/gif",
];

const CreateBlog = ({ onClose, open, data, isAdd, isView, permission }) => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.blog.categoriesComboList);
  const loading = useSelector((state) => state.blog.loading);
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState(data?.image_url || null);
  const [imageFile, setImageFile] = useState(null);
  const [isSlugEditable, setIsSlugEditable] = useState(false);
  const [autoGenerateSlug, setAutoGenerateSlug] = useState(true);

  useEffect(() => {
    if (data && !isAdd) {
      // Set form values
      form.setFieldsValue({
        title: data.title,
        slug: data.slug,
        description: data.description,
        content: data.content,
        category_id: data.category_id,
        published_at: data.published_at ? dayjs(data.published_at) : undefined,
        is_published: data.is_published,
        meta_title: data.meta_title,
        meta_desc: data.meta_desc,
        author_name: data.author_name,
        reading_time: data.reading_time,
        tags: data.tags?.split(",").map((tag) => tag.trim()) || [],
      });

      if (data.image_url) {
        setImageUrl(`${BASE_DOC_URL}/${data.image_url}`);
      }
    }
  }, [data, isAdd, form]);

  useEffect(() => {
    dispatch(getBlogCategories());
  }, [dispatch]);

  
  const handleSaveClick = (e) => {
    e.preventDefault();
    form.submit();
  };

  console.log(categories)
  const onFinish = async (values) => {
    const formData = new FormData();
    if (imageFile) {
      formData.append("image", imageFile);
    }

    const blogData = {
      ...values,
      published_at: values.published_at?.toISOString(),
      tags: values.tags?.join(","),
    };

    formData.append("data", JSON.stringify(blogData));

    try {
      if (isAdd) {
        await dispatch(saveBlog(formData))
          .then((response) => {
            if (response?.payload?.statusCode === 201) {
              toast.success(response?.payload?.message, { duration: 3000 });
              form.resetFields();
              onClose();
            }
          })
          .catch((error) => {
            console.error("Error submitting form:", error);
            toast.error(error, { duration: 3000 });
          });
      } else {
        await dispatch(updateBlog({ id: data.id, formData }))
          .then((response) => {
            if (response?.payload?.statusCode === 200) {
              toast.success(response?.payload?.message, { duration: 3000 });
              onClose();
            }
          })
          .catch((error) => {
            console.error("Error submitting form:", error);
            toast.error(error, { duration: 3000 });
          });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Image handling styles and functions
  const imagePreviewStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "20px",
    justifyContent: "center",
    alignItems: "center",
  };

  const imageContainerStyle = {
    position: "relative",
    width: "fit-content",
  };

  const thumbnailStyle = {
    width: "100px",
    height: "100px",
    objectFit: "cover",
    borderRadius: "8px",
    border: "1px solid #d9d9d9",
    cursor: "pointer",
    transition: "transform 0.2s",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0,0,0,0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0,
    transition: "opacity 0.2s",
    borderRadius: "8px",
    cursor: "pointer",
  };

  const validateImage = (file) => {
    const isAllowedType = ALLOWED_IMAGE_TYPES.includes(file.type);
    if (!isAllowedType) {
      message.error(
        `File type not allowed. Please upload ${ALLOWED_IMAGE_TYPES.map(
          (type) => type.split("/")[1].toUpperCase()
        ).join(", ")} only.`
      );
      return false;
    }
    return true;
  };

  const handleImageChange = (info) => {
    if (info.file.status === "removed") {
      setImageFile(null);
      setImageUrl(null);
      return;
    }

    const file = info.file;
    if (file && validateImage(file)) {
      setImageFile(file);
      const previewUrl = URL.createObjectURL(file);
      setImageUrl(previewUrl);
    }
  };

  const formItemLayout = {
    labelCol: { xs: { span: 24 }, sm: { span: 8 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
  };

  // Rich text editor modules configuration
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  // Function to generate slug from title
  const generateSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/(^-|-$)+/g, "");
  };

  // Handle title change
  const handleTitleChange = (e) => {
    const title = e.target.value;
    form.setFieldsValue({ title });

    if (autoGenerateSlug) {
      const slug = generateSlug(title);
      form.setFieldsValue({ slug });
    }
  };

  // Toggle slug editability
  const toggleSlugEdit = () => {
    setIsSlugEditable(!isSlugEditable);
    setAutoGenerateSlug(false);
  };

  return (
    <Drawer
      title={`${isAdd ? "Add" : isView ? "View" : "Edit"} Blog`}
      placement="right"
      width={800}
      onClose={onClose}
      open={open}
      maskClosable={false}
      extra={
        <Space>
          {permission &&
            (permission.can_create || permission.can_update) &&
            !isView && (
              <Button type="primary" onClick={handleSaveClick}>
                Save
              </Button>
            )}
        </Space>
      }
    >
      <Form
        {...formItemLayout}
        form={form}
        name="blog"
        onFinish={onFinish}
        disabled={isView}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Please enter blog title" }]}
        >
          <Input onChange={handleTitleChange} />
        </Form.Item>

        <Form.Item
          name="slug"
          label={
            <Space>
              Slug
              <Tooltip title={isSlugEditable ? "Lock slug" : "Edit slug"}>
                <Button
                  type="text"
                  icon={<EditOutlined />}
                  onClick={toggleSlugEdit}
                  size="small"
                />
              </Tooltip>
            </Space>
          }
          rules={[{ required: true, message: "Please enter blog slug" }]}
        >
          <Input
            disabled={!isSlugEditable && !isView}
            onChange={() => setAutoGenerateSlug(false)}
          />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: "Please enter blog description" }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item
          name="content"
          label="Content"
          rules={[{ required: true, message: "Please enter blog content" }]}
        >
          <ReactQuill
            theme="snow"
            modules={modules}
            readOnly={isView}
            style={{
              height: "300px",
              marginBottom: "50px",
            }}
          />
        </Form.Item>

        <Form.Item
          name="category_id"
          label="Category"
          rules={[{ required: true, message: "Please select a category" }]}
        >
          <Select
            showSearch
            placeholder="Select a category"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.name ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={categories}
          />
        </Form.Item>

        <Form.Item name="meta_title" label="Meta Title">
          <Input />
        </Form.Item>

        <Form.Item name="meta_desc" label="Meta Description">
          <Input.TextArea rows={3} />
        </Form.Item>

        <Form.Item name="author_name" label="Author Name">
          <Input />
        </Form.Item>

        <Form.Item name="reading_time" label="Reading Time (minutes)">
          <InputNumber min={1} style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item name="tags" label="Tags">
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Enter tags"
            tokenSeparators={[","]}
          />
        </Form.Item>

        <Form.Item name="published_at" label="Publish Date">
          <DatePicker
            className="w-full"
            showTime={{ format: "HH:mm:ss" }}
            format="YYYY-MM-DD HH:mm:ss"
            placeholder="Select date and time"
          />
        </Form.Item>

        <Form.Item
          name="is_published"
          label="Published"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>

        <Form.Item
          name="image"
          label="Blog Image"
          required={isAdd}
          tooltip="Upload blog image"
        >
          <Upload.Dragger
            accept=".jpg,.jpeg,.png,.webp,.gif"
            maxCount={1}
            beforeUpload={() => false}
            onChange={handleImageChange}
            showUploadList={false}
          >
            {imageUrl ? (
              <div style={imagePreviewStyle}>
                <div style={imageContainerStyle}>
                  <img src={imageUrl} alt="blog" style={thumbnailStyle} />
                  <div style={overlayStyle}>
                    <DeleteOutlined
                      style={{ color: "white", fontSize: "20px" }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag image to upload</p>
                <p className="ant-upload-hint">
                  Support for JPG, PNG, WebP, GIF. Max size: 5MB
                </p>
              </>
            )}
          </Upload.Dragger>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default CreateBlog;
