import {
  Button,
  Checkbox,
  Drawer,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Upload,
  Tag,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdCloudUpload } from "react-icons/md";
import toast from "react-hot-toast";
import {
  saveProduct,
  updateProduct,
} from "../../../store/product/productSlice";
import { getCategories } from "../../../store/product/categorySlice";
import { getSubCategoriesByCategory } from "../../../store/product/subcategorySlice";
import { DeleteOutlined, InboxOutlined } from "@ant-design/icons";
import { BASE_DOC_URL } from "../../../utils/actionTypes";

const ALLOWED_IMAGE_TYPES = [
  "image/jpeg",
  "image/png",
  "image/webp",
  "image/gif",
];

const CreateProduct = ({ onClose, open, data, isAdd, isView, permission }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState(data?.image_url || null);
  const [selectedCategory, setSelectedCategory] = useState(
    data?.category_id || null
  );
  const [thumbnailUrl, setThumbnailUrl] = useState(data?.thumbnail_url || null);
  const [thumbnailFile, setThumbnailFile] = useState(null);

  const categories = useSelector((state) => state.category.categoriesComboList);
  const subcategories = useSelector(
    (state) => state.subcategory.subcategoriesComboList
  );

  useEffect(() => {
    dispatch(getCategories());
    if (selectedCategory) {
      dispatch(getSubCategoriesByCategory(selectedCategory));
    }
  }, [dispatch, selectedCategory]);

  useEffect(() => {
    if (data && !isAdd) {
      

      // Set form values
      form.setFieldsValue({
        name: data.name,
        code: data.code,
        description: data.description,
        tags: data.tags,
        price:data.price,
        stock:data.stock,
        is_active:data.is_active,
        category_id:data.category_id,
        subcategory_id:data.subcategory_id
      });

      if (data.image_url) {
        setThumbnailUrl(`${BASE_DOC_URL}/${data.image_url}`);
      }
      
    }
  }, [data, isAdd, form, dispatch]);

  const handleSaveClick = (e) => {
    e.preventDefault();
    form.submit();
  };

  const onFinish = async (values) => {
    const formData = new FormData();
    if (thumbnailFile) {
        formData.append("thumbnail", thumbnailFile);
      }
      const cleanValues = {
        ...values,
      };
      const productDataString = JSON.stringify(cleanValues);

      // Append the data ONCE
      formData.append("data", productDataString);

    try {
      if (isAdd) {
        await dispatch(saveProduct(formData))
          .then((response) => {
            if (
              response &&
              response.payload &&
              response.payload.statusCode === 201
            ) {
              toast.success(
                response && response.payload && response.payload.message,
                { duration: 3000 }
              );
              form.resetFields();
              onClose();
            }
          })
          .catch((error) => {
            console.error("Error submitting form:", error);
            toast.error(error, { duration: 3000 });
          });
      } else {
        await dispatch(updateProduct({ id: data.id, formData }))
          .then((response) => {
            if (
              response &&
              response.payload &&
              response.payload.statusCode === 200
            ) {
              toast.success(
                response && response.payload && response.payload.message,
                { duration: 3000 }
              );
              onClose();
            }
          })
          .catch((error) => {
            console.error("Error submitting form:", error);
            toast.error(error, { duration: 3000 });
          });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
    form.setFieldsValue({ subcategory_id: undefined });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  const normalizeTags = (tags) => {
    if (typeof tags === "string") {
      return tags
        .split(",")
        .map((tag) => tag.trim())
        .filter((tag) => tag);
    }
    return tags || [];
  };

  const imagePreviewStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "20px",
    justifyContent: "center",
    alignItems: "center",
  };

  const imageContainerStyle = {
    position: "relative",
    width: "fit-content",
    "&:hover": {
      "& > div": {
        opacity: 1,
      },
    },
  };

  const thumbnailStyle = {
    width: "100px",
    height: "100px",
    objectFit: "cover",
    borderRadius: "8px",
    border: "1px solid #d9d9d9",
    cursor: "pointer",
    transition: "transform 0.2s",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0,0,0,0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0,
    transition: "opacity 0.2s",
    borderRadius: "8px",
    cursor: "pointer",
  };
  const validateImage = (file) => {
    const isAllowedType = ALLOWED_IMAGE_TYPES.includes(file.type);
    if (!isAllowedType) {
      message.error(
        `File type not allowed. Please upload ${ALLOWED_IMAGE_TYPES.map(
          (type) => type.split("/")[1].toUpperCase()
        ).join(", ")} only.`
      );
      return false;
    }

    // const isLt5M = file.size <= MAX_IMAGE_SIZE;
    // if (!isLt5M) {
    //   message.error("Image must be smaller than 5MB!");
    //   return false;
    // }

    return true;
  };
  const handleThumbnailChange = (info) => {
    if (info.file.status === "removed") {
      setThumbnailFile(null);
      setThumbnailUrl(null);
      return;
    }

    const file = info.file;
    if (file && validateImage(file)) {
      setThumbnailFile(file);
      const previewUrl = URL.createObjectURL(file);
      setThumbnailUrl(previewUrl);
    }
  };

  return (
    <Drawer
      title={`${isAdd ? "Add" : isView ? "View" : "Edit"} Product`}
      placement="right"
      width={600}
      onClose={onClose}
      open={open}
      maskClosable={false}
      extra={
        <Space>
          {permission &&
            (permission.can_create || permission.can_update) &&
            !isView && (
              <Button type="primary" onClick={handleSaveClick}>
                Save
              </Button>
            )}
        </Space>
      }
    >
      <Form
        {...formItemLayout}
        form={form}
        name="product"
        onFinish={onFinish}
        disabled={isView}
        initialValues={{
          id: (data && data.id) || 0,
          name: (data && data.name) || "",
          code: (data && data.code) || "",
          description: (data && data.description) || "",
          price: (data && data.price) || 0,
          stock: (data && data.stock) || 0,
          category_id: (data && data.category_id) || undefined,
          subcategory_id: (data && data.subcategory_id) || undefined,
          tags: normalizeTags(data?.tags),
          is_active: (data && data.is_active) ?? true,
        }}
        scrollToFirstError
      >
        <Form.Item
          name="category_id"
          label="Category"
          rules={[
            {
              required: true,
              message: "Please select a category",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select a category"
            optionFilterProp="children"
            onChange={handleCategoryChange}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={categories}
          />
        </Form.Item>

        <Form.Item
          name="subcategory_id"
          label="Subcategory"
          rules={[
            {
              required: false,
              message: "Please select a subcategory",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select a subcategory"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={subcategories}
            disabled={!selectedCategory}
          />
        </Form.Item>

        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please enter product name",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="code"
          label="Code"
          rules={[
            {
              required: true,
              message: "Please enter product code",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[
            {
              message: "Please enter product description",
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item
          name="price"
          label="Price"
          rules={[
            {
              required: true,
              message: "Please enter product price",
            },
          ]}
        >
          <InputNumber
            min={0}
            step={0.01}
            precision={2}
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          name="stock"
          label="Stock"
          rules={[
            {
              required: true,
              message: "Please enter product stock",
            },
          ]}
        >
          <InputNumber min={0} style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item name="tags" label="Tags">
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Enter tags"
            tokenSeparators={[","]}
          />
        </Form.Item>

        

        <Form.Item
          name="thumbnail"
          label="Thumbnail"
          required={isAdd}
          tooltip="Upload product thumbnail"
          className="mb-8"
        >
          <Upload.Dragger
            accept=".jpg,.jpeg,.png,.webp,.gif"
            maxCount={1}
            beforeUpload={() => false}
            onChange={handleThumbnailChange}
            showUploadList={false}
          >
            {thumbnailUrl ? (
              <div style={imagePreviewStyle}>
                <div style={imageContainerStyle}>
                  <img
                    src={thumbnailUrl}
                    alt="thumbnail"
                    style={thumbnailStyle}
                  />
                  <div style={overlayStyle}>
                    <DeleteOutlined
                      style={{ color: "white", fontSize: "20px" }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag thumbnail to upload
                </p>
                <p className="ant-upload-hint">
                  Support for JPG, PNG, WebP, GIF. Max size: 5MB
                </p>
              </>
            )}
          </Upload.Dragger>
        </Form.Item>

        <Form.Item
          name="is_active"
          valuePropName="checked"
          {...tailFormItemLayout}
        >
          <Checkbox>Active?</Checkbox>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default CreateProduct;
