import React, { useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Table,
  Badge,
  Progress,
  Spin,
  Alert,
  Typography,
} from "antd";
import {
  ShoppingOutlined,
  AppstoreOutlined,
  TagsOutlined,
  UserOutlined,
  WarningOutlined,
  RiseOutlined,
  FallOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  PieChart,
  Pie,
  Cell,
  Legend,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardStats } from "../../store/dashboard/dashboardSlice";
import "./Dashboard.css";
import { BASE_DOC_URL } from "../../utils/actionTypes";

const { Column } = Table;

const { Title, Text } = Typography;
const COLORS = [
  "#1890ff",
  "#52c41a",
  "#faad14",
  "#eb2f96",
  "#722ed1",
  "#13c2c2",
];

const Dashboard = () => {
  
  return (
    <div>Dashboard</div>
  );
};

export default Dashboard;
