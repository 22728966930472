import { Button, Checkbox, Drawer, Form, Input, Space } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import {
  saveCategory,
  updateCategory,
} from "../../../../store/product/categorySlice";

const CreateCategory = ({ onClose, open, data, isAdd, isView, permission }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleSaveClick = (e) => {
    e.preventDefault();
    form.submit();
  };

  const onFinish = async (values) => {
    const model = {
      ...values,
      id: isAdd ? "" : data.id,
    };

    try {
      if (isAdd) {
        await dispatch(saveCategory(model))
          .then((response) => {
            if (
              response &&
              response.payload &&
              response.payload.statusCode === 201
            ) {
              toast.success(
                response && response.payload && response.payload.message,
                { duration: 3000 }
              );
              form.resetFields();
              onClose();
            }
          })
          .catch((error) => {
            console.error("Error submitting form:", error);
            toast.error(error, { duration: 3000 });
          });
      } else {
        await dispatch(updateCategory(model))
          .then((response) => {
            if (
              response &&
              response.payload &&
              response.payload.statusCode === 200
            ) {
              toast.success(
                response && response.payload && response.payload.message,
                { duration: 3000 }
              );
              onClose();
            }
          })
          .catch((error) => {
            console.error("Error submitting form:", error);
            toast.error(error, { duration: 3000 });
          });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  return (
    <Drawer
      title={`${isAdd ? "Add" : isView ? "View" : "Edit"} Category`}
      placement="right"
      width={600}
      onClose={onClose}
      open={open}
      maskClosable={false}
      extra={
        <Space>
          {permission &&
            (permission.can_create || permission.can_update) &&
            !isView && (
              <Button type="primary" onClick={handleSaveClick}>
                Save
              </Button>
            )}
        </Space>
      }
    >
      <Form
        {...formItemLayout}
        form={form}
        name="category"
        onFinish={onFinish}
        disabled={isView}
        initialValues={{
          id: (data && data.id) || 0,
          name: (data && data.name) || "",
          code: (data && data.code) || "",
          description: (data && data.description) || "",
          is_active: (data && data.is_active) ?? true,
        }}
        scrollToFirstError
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please enter category name",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="code"
          label="Code"
          rules={[
            {
              required: true,
              message: "Please enter category code",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[
            {
              message: "Please enter category description",
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item
          name="is_active"
          valuePropName="checked"
          {...tailFormItemLayout}
        >
          <Checkbox>Active?</Checkbox>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default CreateCategory;
