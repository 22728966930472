import { Button, Checkbox, Drawer, Form, Input, Select, Space } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  saveSubCategory,
  updateSubCategory,
} from "../../../../store/product/subcategorySlice";
import { getCategories } from "../../../../store/product/categorySlice";

const CreateSubCategory = ({
  onClose,
  open,
  data,
  isAdd,
  isView,
  permission,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const categories = useSelector((state) => state.category.categoriesComboList);

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const handleSaveClick = (e) => {
    e.preventDefault();
    form.submit();
  };

  const onFinish = async (values) => {
    const model = {
      ...values,
      id: isAdd ? "" : data.id,
    };

    try {
      if (isAdd) {
        await dispatch(saveSubCategory(model))
          .then((response) => {
            if (
              response &&
              response.payload &&
              response.payload.statusCode === 201
            ) {
              toast.success(
                response && response.payload && response.payload.message,
                { duration: 3000 }
              );
              form.resetFields();
              onClose();
            }
          })
          .catch((error) => {
            console.error("Error submitting form:", error);
            toast.error(error, { duration: 3000 });
          });
      } else {
        await dispatch(updateSubCategory(model))
          .then((response) => {
            if (
              response &&
              response.payload &&
              response.payload.statusCode === 200
            ) {
              toast.success(
                response && response.payload && response.payload.message,
                { duration: 3000 }
              );
              onClose();
            }
          })
          .catch((error) => {
            console.error("Error submitting form:", error);
            toast.error(error, { duration: 3000 });
          });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  return (
    <Drawer
      title={`${isAdd ? "Add" : isView ? "View" : "Edit"} Subcategory`}
      placement="right"
      width={600}
      onClose={onClose}
      open={open}
      maskClosable={false}
      extra={
        <Space>
          {permission &&
            (permission.can_create || permission.can_update) &&
            !isView && (
              <Button type="primary" onClick={handleSaveClick}>
                Save
              </Button>
            )}
        </Space>
      }
    >
      <Form
        {...formItemLayout}
        form={form}
        name="subcategory"
        onFinish={onFinish}
        disabled={isView}
        initialValues={{
          id: (data && data.id) || 0,
          name: (data && data.name) || "",
          code: (data && data.code) || "",
          description: (data && data.description) || "",
          category_id: (data && data.category_id) || undefined,
          is_active: (data && data.is_active) ?? true,
        }}
        scrollToFirstError
      >
        <Form.Item
          name="category_id"
          label="Category"
          rules={[
            {
              required: true,
              message: "Please select a category",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select a category"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={categories}
          />
        </Form.Item>

        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please enter subcategory name",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="code"
          label="Code"
          rules={[
            {
              required: true,
              message: "Please enter subcategory code",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[
            {
              message: "Please enter subcategory description",
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item
          name="is_active"
          valuePropName="checked"
          {...tailFormItemLayout}
        >
          <Checkbox>Active?</Checkbox>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default CreateSubCategory;
